<template>
  <section class="content-section rat-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5" v-if="rat['name']">
              {{ rat['name'] }}
          </h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row" v-if="$store.state.nftContract">
        <div class="col-lg-3 col-sm-12 text-justify">
          <p v-if="owner">
            <strong>Owner: </strong> <MetamaskAddress  v-bind:address="owner" short="yes"></MetamaskAddress>
          </p>
          <p v-if="owner && $store.state.address && owner.toLowerCase() === $store.state.address.toLowerCase()">
            <router-link v-if="$store.state.address === owner.toLowerCase()" v-bind:to="'/rat/' + $route.params.id" class="btn btn-primary">Fitting room</router-link>
          </p>
          <Image v-if="nft.equipment" v-bind:equipment="nft.equipment"></Image>
          <p class="badges">
            <span class="badge bg-secondary" v-if="rat.generation == 2">Series: Xmas Special edition 🎅</span>
            <span class="badge bg-secondary" v-if="rat.duplicates == 1">Unique ⭐️</span>
            <span class="badge bg-secondary iq" v-if="rat.power">{{ $store.getters.config.power }}: {{ rat.power }} {{ $store.getters.config.powerIcon }}</span>
          </p>
          <template v-if="$store.getters.config.chainId === 10000">
            <div v-if="!rat.smart">
              <a target="_blank" v-bind:href="'https://simpleledger.info/token/' + metadata.hash">
                <img src="/images/slp.png" />
              </a>
            </div>
            <div v-if="rat.smart">
              <img src="/images/smartbch.png" width="256" class="img-fluid" />
            </div>
          </template>
          <div v-if="$store.getters.config.chainId === 2000">
            <img src="/images/dogechain.png" width="256" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-9 col-sm-12">
          <template v-if="owner">
            <!--MarketV3
                v-bind:ca="nftCA"
                v-bind:id="rat.number"
                v-bind:owner="owner"
                brief="no">
            </MarketV3>
            <Bid
                v-bind:ca="nftCA"
                v-bind:id="rat.number"
                v-bind:owner="owner">
            </Bid-->
            <Transfer
                v-if="owner.toLowerCase() == $store.state.address.toLowerCase()"
                v-bind:ca="$store.getters.config.nftCA"
                v-bind:id="rat.number">
            </Transfer>
          </template>

          <div v-if="rat.attributes">
            <strong>
              Base traits
            </strong>: <br/>
            <div class="container">
              <div class="row">
                <template v-for="attribute in rat.attributes" :key="attribute.trait_type">
                  <div class="col-md-4 col-sm-6 col-12" v-if="attribute.slot === 0">
                    <div class="card mb-3 text-center">
                      <div class="card-body">
                        <div class="d-flex justify-content-center">
                          <small>{{ attribute.trait_type }}</small>
                        </div>
                        <div class="d-flex justify-content-center">
                          <strong>{{ attribute.value }} - {{ $store.getters.config.powerIcon }} {{ attribute.power }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div v-if="nft.hasEquipment">
            <strong>
              Equipped traits
            </strong>: <br/>
            <div class="container">
              <div class="row">
                <template v-for="attribute in rat.attributes" :key="attribute.trait_type">
                  <div class="col-md-4 col-sm-6 col-12" v-if="attribute.slot > 0">
                    <div class="card mb-3 text-center">
                      <div class="card-body">
                        <div class="d-flex justify-content-center">
                          <small>{{ attribute.trait_type }}</small>
                        </div>
                        <div class="d-flex justify-content-center">
                          <strong>{{ attribute.value }} - {{ $store.getters.config.powerIcon }} {{ attribute.power }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <p v-if="rat['rat-data'] && rat['rat-data'].length > 0">
            <strong>Data</strong><br/>
            <code>{{ rat['rat-data'] }}</code>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
p.badges .badge {
  margin: 5px;
}
</style>

<script>
  import Transfer from "@/components/Rat/Transfer";
  import Image from "@/components/Rat/Image";
  import MetamaskAddress from "@/components/MetamaskAddress";

  export default {
    components: {
      MetamaskAddress,
      Transfer,
      Image
    },
    data() {
      return {
        nftCA: null,
        similarRats: [],
        owner: null,
        power: 0,
        nft: {
          metadata: [],
          equipment: null,
          hasEquipment: false,
        },
        rat: {
          firstname: null,
          name: null,
          number: null,
          hash: null,
          image: null,
          'rat-number': null,
          'rat-data': null,
          traits: {},
          duplicates: null,
          lookup: null,
          generation: 1,
          smart: true,
        },
      }
    },
    watch: {
      '$route.params.id': function() {
        this.load(this.$route.params.id);
      },
      '$store.state.nftContract': function() {
        this.load(this.$route.params.id);
      }
    },
    methods: {
      load: function(id) {
        if(!this.$store.state.nftContract) {
          return;
        }
        this.$store.state.nftContract.tokenURI(id).then(uri => {
          console.log(uri);
          let uriParts = uri.split('/');
          this.nft.equipment = uriParts[uriParts.length - 1];
          fetch(uri).then((response) => {
            return response.json();
          }).then((rat) => {
            this.rat = rat;
            for(var i in rat.attributes) {
              if(typeof rat.attributes[i].power !== 'undefined' && rat.attributes[i].power > 0) {
                this.nft.hasEquipment = true;
              }
            }
            this.metadata = rat;
            this.$store.state.nftContract.nftPower(id).then(power => {
              this.power = power;
            });
            try {
              this.$store.state.nftContract.ownerOf(id).then((owner) => {
                this.rat.smart = true;
                this.owner = owner;
              }).catch(() => {
                this.rat.smart = false;
              });
            } catch(e) {
              console.log(e.message);
            }
          });
        }).catch(() => {
          this.nft.equipment = id.toString();
          fetch('https://api.cryptor.at/nightmares/nft/' + id).then((response) => {
            return response.json();
          }).then((rat) => {
            this.rat = rat;
            this.metadata = rat;
            this.power = rat['power'];
            this.owner = null;
          });
        });
      }
    },
    mounted() {
      this.load(this.$route.params.id);
    }
  }
</script>
